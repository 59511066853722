import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ProfilePermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.complete = permissionChecker.match(
            Permissions.values.profileComplete,
        );

        this.editCompany = permissionChecker.match(
            Permissions.values.companyProfileEdit,
        );

    }
}
